<template>
  <transition appear
              name="scale">
    <div class="review-tourists">
      <div class="review-tourists__wrapper">
        <Row justify="between">
          <Column class="review-tourists__image-column">
            <div class="review-tourists__image-holder">
              <img :src="params.images[0]"
                   alt=""
                   class="review-tourists__image"/>
            </div>
          </Column>
          <Column class="review-tourists__info">
            <p class="review-tourists__title">{{params.title}}</p>
            <p class="review-tourists__description">{{description}}</p>
          </Column>
        </Row>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ReviewTourists',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description() {
      let string = this.params.description;
      if (string.length > 220) {
        string = string.slice(0, 220);
        string = `${string}...`;
      }
      return string;
    },
  },
};
</script>
